import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"
import { v4 } from "uuid"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb } from "../components/Section"
import { BulletIcon } from "../components/Icons"
import TopPageHeader from "../components/TopPageHeader"
import BreakpointUp from "../components/Media/BreakpointUp"
import ArticleCard from "../components/ArticleCard"
import RecentArticleCard from "../components/RecentArticleCard"

const GridRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.lg`
        margin-right: -30px;
        margin-left: -30px;
    `}
`
const GridLeftColumn = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`	    
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    `}
  ${BreakpointUp.lg`	     
        padding:0 30px;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    `}
`
const GridRightColumn = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
  ${BreakpointUp.lg`	               
        padding:0 30px;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
    & h2 {
    margin-bottom: 5px;
  }
`
const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.xl`
    margin-right: -20px;
    margin-left: -20px;
  `}
`
const SectionItem = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`
    padding-right: 20px;
    padding-left: 20px;
  `}
`
const CardSidebarGroup = styled.div`
  padding: 0;
`
const CardSidebar = styled.div`
  position: relative;
`
const CardSidebarBody = styled.div`
  padding: 30px 0;
`
const CardSidebarHeader = styled.div`
  background-image: linear-gradient(to right, #707070 50%, #fff 0%);
  background-position: bottom center;
  background-size: 9px 1px;
  background-repeat: repeat-x;
  width: 100%;
  padding-bottom: 20px;
  ${BreakpointUp.sm`
        padding-bottom: 30px;
    `}
`
const CardSidebarTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 26px;
  }
  @media (min-width: 992px) {
    font-size: 26px;
    line-height: 30px;
  }
`
const ListGroupFlush = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  margin: 0;
  a {
    color: #6a6a6a;
    &:hover {
      color: #211f28;
    }
  }
`
const ListItemFlush = styled.div`
  padding-bottom: 20px;
  ${BreakpointUp.sm`
        padding-bottom:30px;
    `}
`

const ListGroupThumb = styled.div`
  margin: 0;
`
const ListItemThumb = styled.div`
  margin: 0;
`

function BlogCategoryPage({ data, pageContext, intl }) {
  const pageData = data.contentfulArticleListPage
  const articles = data.allContentfulArticle.edges
  const categories = data.allContentfulArticleCategory.edges
  const currentCategory = data.contentfulArticleCategory.name
  return (
    <Layout>
      <SEO title="Articles Category" description="Articles Category" />
      <BreadCrumb>
        <div className="container">
          <Link to={`/${intl.locale}`}>
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <Link to={`/${intl.locale}/about-us`}>
            {intl.formatMessage({ id: "About Us" })} <BulletIcon />
          </Link>
          <Link to={`/${intl.locale}/blog`}>
            {intl.formatMessage({ id: "Blog" })} <BulletIcon />
          </Link>
          <span>{currentCategory}</span>
        </div>
      </BreadCrumb>
      <TopPageHeader
        topHeaderTitle={pageData.heroTitle}
        topHeaderDesc={pageData.heroDescription}
      />
      <Section pt="110px" pb="90px" xpt="70px" xpb="60px" bgColor="#F9F9F9">
        <div className="container">
          <GridRow>
            <GridLeftColumn>
              <SectionGrid>
                {articles.map(edge => {
                  if (edge.node.category.name === currentCategory) {
                    return (
                      <SectionItem key={v4()}>
                        <ArticleCard data={edge.node} intl={intl} />
                      </SectionItem>
                    )
                  }
                  return true
                })}
              </SectionGrid>
            </GridLeftColumn>
            <GridRightColumn>
              <CardSidebarGroup>
                <CardSidebar>
                  <CardSidebarHeader>
                    <CardSidebarTitle>
                      {intl.formatMessage({ id: "Categories" })}
                    </CardSidebarTitle>
                  </CardSidebarHeader>
                  <CardSidebarBody>
                    <ListGroupFlush>
                      {categories.map(item => {
                        let flag = 0
                        articles.forEach(article => {
                          if (item.node.name === article.node.category.name) {
                            flag = flag + 1
                          }
                        })
                        return (
                          <ListItemFlush>
                            <Link to={`/${intl.locale}/blog${item.node.slug}`}>
                              - {item.node.name} ({flag})
                            </Link>
                          </ListItemFlush>
                        )
                      })}
                    </ListGroupFlush>
                  </CardSidebarBody>
                </CardSidebar>
                <CardSidebar>
                  <CardSidebarHeader>
                    <CardSidebarTitle>
                      {intl.formatMessage({ id: "Recent Posts" })}
                    </CardSidebarTitle>
                  </CardSidebarHeader>
                  <CardSidebarBody>
                    <ListGroupThumb>
                      {articles.map((item, i) => {
                        if (i < 4) {
                          return (
                            <ListItemThumb key={v4()}>
                              <RecentArticleCard data={item.node} intl={intl} />
                            </ListItemThumb>
                          )
                        }
                        return true
                      })}
                    </ListGroupThumb>
                  </CardSidebarBody>
                </CardSidebar>
              </CardSidebarGroup>
            </GridRightColumn>
          </GridRow>
        </div>
      </Section>
    </Layout>
  )
}

export default injectIntl(BlogCategoryPage)

export const query = graphql`
  query BlogCategoryPageQuery($locale: String, $slug: String) {
    contentfulArticleCategory(
      node_locale: { eq: $locale }
      slug: { eq: $slug }
    ) {
      node_locale
      name
      slug
    }
    contentfulArticleListPage(node_locale: { eq: $locale }) {
      node_locale
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulArticle(
      sort: { order: DESC, fields: postDate }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          node_locale
          title
          slug
          postDate(formatString: "D MMM YYYY")
          category {
            name
          }
          image {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
            description
          }
          content {
            childMarkdownRemark {
              excerpt(pruneLength: 270)
            }
          }
        }
      }
    }
    allContentfulArticleCategory(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
