import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import BreakpointUp from "../Media/BreakpointUp"

const CardInfo = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 15px;
  ${BreakpointUp.md`
        align-items: center;
        margin-bottom:30px;
    `}
`
const CardInfoBody = styled.div`
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
`
const CardInfoTitle = styled.h3`
  font-family: "Rajdhani", sans-serif;
  color: #6a6a6a;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0;
  a {
    color: #6a6a6a;
    &:hover,
    &:focus {
      color: #211f28;
    }
  }
`

const CardInfoFigure = styled.div`
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;

  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  .gatsby-image-wrapper {
    width: 100%;
  }
`
const PostedDate = styled.span`
  display: inline-block;
  background-color: #f6f6f6;
  color: #6a6a6a;
  font-size: 12px;
  line-height: 18px;
  min-width: 90px;
  min-height: 18px;
  padding: 0 3px;
`
const RecentArticleCard = ({ intl, data }) => {
  return (
    <CardInfo className="card">
      <CardInfoFigure className="card-img">
        <Img fluid={data.image.fluid} alt={data.image.description} />
      </CardInfoFigure>
      <CardInfoBody className="card-body">
        <PostedDate className="post-date">{data.postDate}</PostedDate>
        <CardInfoTitle>
          <Link to={`/${intl.locale}${data.slug}`}>{data.title}</Link>
        </CardInfoTitle>
      </CardInfoBody>
    </CardInfo>
  )
}

export default RecentArticleCard
