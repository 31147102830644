import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import DefaultButton from "../Button/DefaultButton"
import { ArrowRightIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"

const CardInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  position: relative;
  transition: 0.3s all;
  &:hover {
    transform: translatey(-10px);
    .card-body {
      box-shadow: 0px 5px 28px 2px rgba(14, 20, 28, 0.25);
    }
  }
`
const CardInfoBody = styled.div`
  position: relative;
  padding: 20px 20px 30px 20px;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.1);
  position: relative;
  z-index: 1;
  margin: -15px 10px 0 10px;
  width: 100%;
  transition: 0.3s all;
  ${BreakpointUp.xl`
        margin: -15px 15px 0 15px;
    `}
`
const CardInfoSubTitle = styled.span`
  font-family: "Rajdhani", sans-serif;
  color: #211f28;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 5px;
`
const CardInfoTitle = styled.h3`
  margin: 0 0 20px;
  color: #6a6a6a;
  font-size: 22px;
  line-height: 28px;
  ${BreakpointUp.lg`    
        font-size:26px;
        line-height:34px;
    `}
  a {
    color: #6a6a6a;
    &:hover,
    &:focus {
      color: #211f28;
    }
  }
`

const CardInfoFigure = styled(Link)`
  position: relative;
  width: 100%;
  margin: 0;
  & .gatsby-image-wrapper {
    height: 100%;
  }
`
const PostedDate = styled.div`
  position: absolute;
  top: -15px;
  text-align: center;
  padding: 10px 3px;
  background-color: #666;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  right: 0;
  ${BreakpointUp.lg`        
        right: -15px;
    `}
  strong {
    display: block;
    font-size: 32px;
    line-height: 24px;
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
  }
`
const ButtonToolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    background-image: linear-gradient(to right, #707070 50%, #fff 0%);
    background-position: 0 0;
    background-size: 9px 1px;
    background-repeat: repeat-x;
    width: 50%;
    height: 1px;
    transform: translateY(-50%);
    z-index: -1;
  }
`

const ArticleCard = ({ data, intl }) => {
  return (
    <CardInfo className="card">
      <PostedDate className="post-date">
        <strong>{data.postDate.substring(0, 2)}</strong>
        {data.postDate.substring(2)}
      </PostedDate>
      <CardInfoFigure className="card-img" to={`/${intl.locale}${data.slug}`}>
        <Img fluid={data.image.fluid} alt={data.image.description} />
      </CardInfoFigure>
      <CardInfoBody className="card-body">
        <CardInfoSubTitle>{data.category.name}</CardInfoSubTitle>
        <CardInfoTitle>
          <Link to={`/${intl.locale}${data.slug}`}>{data.title}</Link>
        </CardInfoTitle>
        <ButtonToolbar>
          <Link to={`/${intl.locale}${data.slug}`}>
            <DefaultButton
              icon={<ArrowRightIcon />}
              text={intl.formatMessage({ id: "read more" })}
            />
          </Link>
        </ButtonToolbar>
      </CardInfoBody>
    </CardInfo>
  )
}

export default ArticleCard
